import React, { Component, useState } from 'react';
import { Col, Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import axios from "axios";
const config = require('../../data/siteConfig')

  
class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'message': '',
            'issue': '',
            'name': '',
            'email': '',
            validate: {
                emailState: '',
                nameState: '',
                issueState: '',
                messageState:''
            },
            serverState:{
                submitting: false,
                status: null
            },
            serverUrl:config.serverUrl
        }
        let stateBack = this.state;
      
        this.handleChange = this.handleChange.bind(this);
       

    }
   
    validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state
        if (emailRex.test(e.target.value)) {
            validate.emailState = 'has-success'
        } else {
            validate.emailState = 'has-danger'
        }
        this.setState({ validate })
    }

 
    handleChange = async (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        await this.setState({
            [name]: value,
        });
    }
        
    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState({
          [name]: value,
        })
      }

    validateText() {
     
        const { validate } = this.state
        validate.nameState =  this.state.name.trim() !== '' ? 'has-success' : 'has-danger';
        validate.issueState = this.state.issue.trim() !== '' ? 'has-success' : 'has-danger';
        validate.messageState =  this.state.message.trim() !== '' ? 'has-success' : 'has-danger';
        this.setState({ validate })
    }

    handleServerResponse(ok, msg, form) {
        const { serverState} = this.state
        serverState.submitting = false;
        serverState.status= { ok, msg };
        this.setState({ serverState });
     
        if (ok) {
            form.reset();
            this.setState({
                message: '',
                issue: '',
                name: '',
                email: '',
              });
        }
      }

    submitForm(e) {
        const { serverUrl } = this.state
        e.preventDefault();
        this.validateEmail(e);
        this.validateText();
        const form = e.target;
        var headers = {
            'Content-Type': 'application/json' 
        }
        this.setState({
            serverState:{
            submitting: true
          }});
        axios({
          method: "POST",
          url: serverUrl+"portalPuntano/contact",
        data: this.state,
          headers: headers
          
        })
            .then(r => {
            this.handleServerResponse(true, r.data.response, form);
          })
            .catch(r => {
            this.handleServerResponse(false, 'Fallo el envio del formulario, intente nuevamente en unos minutos', form);
          });
    }
    
    render() {
        const { validate } = this.state;
        return (
            <Form onSubmit={(e) => this.submitForm(e)} className="contact_form">
                <FormGroup row>
                    <Label for="contactName" sm={2} md={1}>Nombre</Label>
                     <Col sm={10} md={11}>
                        <Input type="text" name="name" id="contactName" value={this.state.name} onChange={this.handleInputChange} 
                        valid={this.state.name !== undefined && this.state.name.trim().length > 0 ? this.state.name : undefined} placeholder="Ingrese su nombre"
                        required/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="contactEmail" sm={2} md={1}>E-mail</Label>
                    <Col sm={10} md={11}>
                        <Input type="email" name="email" id="contactEmail" value={this.state.email} onChange={this.handleInputChange}
                           valid={this.state.email !== undefined && this.state.email.trim().length > 0 ? this.state.email : undefined } required placeholder="Ingrese un email para poder contactarnos"/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="contactIssue" sm={2} md={1}>Asunto</Label>
                    <Col sm={10} md={11}>
                        <Input type="text" name="issue" id="contactIssue" value={ this.state.issue } onChange={this.handleInputChange}
                            valid={this.state.issue !== undefined && this.state.issue.trim().length > 0 ? this.state.issue : undefined } required placeholder="Ingrese un asunto para saber sobre su consulta"/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                     <Label for="contactMessage" sm={2} md={1}>Mensaje</Label>
                    <Col sm={10} md={11}>
                        <Input type="textarea" name="message" id="contactMessage" value={this.state.message} onChange={this.handleInputChange}
                           valid={this.state.message !== undefined && this.state.message.trim().length > 0 ? this.state.message : undefined} required placeholder="Escriba un mensaje"/>
                    </Col>
                </FormGroup>
                <Button className="navBtn center" disabled={this.state.serverState.submitting}>ENVIAR</Button>
                {this.state.serverState.status && (
                <p className={!this.state.serverState.status.ok ? "errorMsg" : "mailOk"}>
                {this.state.serverState.status.msg}</p>
            )}
            </Form>
        );
    }
}

export default ContactForm;