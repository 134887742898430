import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import { Container, Row, Col } from 'reactstrap'
import Link from '../components/link'
import Box from '../components/box'
import styled from 'styled-components'
import Image from "gatsby-image"
import Hero from '../components/Hero'
import Breadcrumb  from "../components/breadcrumb"
import ContactForm from '../components/contactForm'
import useSiteMetadata from '../hooks/use-site-config'
import { Helmet } from "react-helmet"

export const query = graphql`
  query {
    coverTechnicalSupport: file(relativePath: { eq: "assets/images/technical_support_images/t_s_cover_image.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200){
          ...GatsbyImageSharpFluid
        }
      }
    }
    windowIcon: file(relativePath: { eq: "assets/images/technical_support_images/t_s_window_icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iosIcon: file(relativePath: { eq: "assets/images/technical_support_images/t_s_apple_icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    appIcon: file(relativePath: { eq: "assets/images/technical_support_images/t_s_app_icon.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

//<Img fluid={data.fileName.childImageSharp.fluid} alt="" />

const AccessImage = styled(Image)`
  border-radius: 10px;
  max-height: 100px;
  margin: 3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  
`
const BorderLessWrapper = styled(Box)`
  padding-left: 1rem;
  border: none ;
  transition-duration: 0.3s;
  text-align: left;
  `

//fomulario de contacto

let Access = ({ title, link, image }) => (
  <Col>
    <Link to={link} className="link">
      <Box>
        <AccessImage fluid={image} imgStyle={{ objectFit: "contain", }} />
        <h6 className="text-center">{title}</h6>
      </Box>
    </Link>
  </Col>
)


export default function TechnicalSupport(props) {
  const { siteTitle } = useSiteMetadata()
  const appIcon = props.data.appIcon.childImageSharp.fluid
  const iosIcon = props.data.iosIcon.childImageSharp.fluid
  const windowIcon = props.data.windowIcon.childImageSharp.fluid
  const coverTechnicalSupport = props.data.coverTechnicalSupport.childImageSharp.fluid
  const title="Soporte Técnico | "+ siteTitle
  return (
    <Layout>
          <Helmet title={title}>
          <meta charSet="utf-8" />
        </Helmet>
      <Hero heroImg={coverTechnicalSupport.src} classStyle="hero_procedure" />
      <Container>
        <Row>
          <Col>
            <Link to={"/technicalSupport/windows-config"} className="link">
              <BorderLessWrapper>
                <AccessImage fluid={windowIcon} imgStyle={{ objectFit: "contain", }} />
                <h6 className="text-center">{"Acceder desde Windows"}</h6>
              </BorderLessWrapper>
            </Link>
          </Col>

          <Access title={"Instalar APP"} link={"/installApp"} image={appIcon} />

          <Access title={"Acceder desde MAC"} link={"/technicalSupport/mac-config"} image={iosIcon} />
        </Row>

        <hr className="separator"></hr>

        <Row>
          <Col lg="10" className="col_contact_form">
            <h5>FORMULARIO DE CONTATO</h5>
            <ContactForm />
          </Col>
        </Row>
      </Container>
      <Breadcrumb pageContext={props.pageContext} label="Soporte Técnico"/>
    </Layout>
  );
}
